<template>
  <main class="member-shop-order-manage">
    <PageTitle title="點數兌換紀錄" btn="匯出" @btnClick="showExportOptions = true" />
    <div class="filters-wrapper">
      <div class="filter-row">
        <p class="label">篩選</p>
        <div>
          <div class="flex gap-[8px]">
            <BaseElSelect
              v-model="search.status"
              class="test"
              clearable
              placeholder="選擇訂單狀態"
              @change="refresh(true)"
              @clear="refresh(true)"
            >
              <BaseElSelectOption
                v-for="item in orderStatusConfig"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </BaseElSelect>
            <BaseElSelect
              v-model="search.payment"
              class="test"
              clearable
              placeholder="選擇付款狀態"
              @change="refresh(true)"
              @clear="refresh(true)"
            >
              <BaseElSelectOption
                v-for="item in orderPaymentStatusConfig"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </BaseElSelect>
            <el-date-picker
              v-model="search.range"
              editable
              class="test2"
              type="datetimerange"
              range-separator="至"
              start-placeholder="開始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              @change="refresh(true)"
            />
          </div>
          <CustomFlowSelect
            class="mt-[8px]"
            :showCustomFlow="showCustomFlow"
            :search="search"
            :displayCustomFlowConfig="displayCustomFlowConfig"
            :customFlowList="customFlowList"
            @refresh="refresh(true)"
          />
        </div>
      </div>
      <div class="filter-row">
        <p class="label">搜尋</p>
        <BaseElInput
          v-model="search.code"
          class="test2"
          placeholder="輸入訂單編號"
          clearable
          @clear="refresh(true)"
          @keypress.enter.native="refresh(true)"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="refresh(true)"
          />
        </BaseElInput>
        <MemberSearch class="test2" :model.sync="search.member" @change="refresh(true)" @clear="refresh(true)" />
      </div>
    </div>
    <MemberShopPointExchangeOrderTable v-loading="loading.table" :tableData="tableData" :customFlowConfig="customFlowConfig" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      :useExportTask="useExportTask"
      info="提醒：資料匯出上限為50,000筆，若超過則會匯出最新的50,000筆。"
      @close="showExportOptions = false"
      @export="prepareExport"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportTotal"
      @close="resetExport"
    />
  </main>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, computed, onActivated } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import CustomFlowSelect from '@/components/Select/CustomFlowSelect.vue'
import MemberShopPointExchangeOrderTable from './components/MemberShopPointExchangeOrderTable.vue'
import { orderStatusConfig, orderPaymentStatusConfig, orderPaymentTypeConfig } from '@/config/memberShop'
import { CreateMemberStoreOrderPointExchangeSheetExportTask } from '@/api/exportTask'
import { useFetch } from '@/use/fetch'
import { useTable } from '@/use/table'
import store from '@/store'
import { GetMemberShopOrder, GetMemberShopOrderCount } from '@/api/memberShop'
import { get, find, isEmpty } from 'lodash'
import { ExportExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'
import { useCustomFlow } from '@/use/useCustomFlow'
import CustomFlowChangeLog from '@/components/CustomFlowChangeLog.vue'
import { orderCustomFlowRecordPathConfig } from '@/config/customOrderStatus'
import { usePermissions } from '@/use/permissions'
import { useExportCenter } from '@/use/useExportCenter'

export default defineComponent({
  name: 'MemberShopPointExchangeRecord',
  components: {
    MemberShopPointExchangeOrderTable,
    MemberSearch,
    PageTitle,
    ExportOptionsDialog,
    ExportDialog,
    CustomFlowSelect,
  },
  setup (props) {
    const { tableData, tableOptions, tableDataCount, loading, pageStartIndex } = useTable()
    const {
      customFlowConfig, displayCustomFlowConfig, getCustomFlowConfig, useCustomFlowFeature,
      getExportCustomFlowFieldsData,
    } = useCustomFlow('pointExchange')
    const shopId = computed(() => store.getters.shop)
    const { messageOptions } = useExportCenter()
    const { fetchWithAmount } = useFetch()
    const { checkAction } = usePermissions()
    const showCustomFlow = computed(() => get(useCustomFlowFeature, 'value.config') && !isEmpty(displayCustomFlowConfig.value)) // 有開權限以及有自定義流程
    const search = reactive({
      code: null,
      member: null,
      status: null,
      range: null,
      payment: null,
      customFlow: '',
      customFlowId: null,
    })
    const showExportOptions = ref(false)
    const showExportDialog = ref(false)
    const exportting = ref(false)
    const exportError = ref(false)
    const exportTotal = ref({})
    const exportData = ref({})
    const exportPercentage = ref(0)
    const useExportTask = computed(() => checkAction('admin.memberStoreOrder.createSheetExportTaskPointBasic'))
    const customFlowList = computed(() =>
      get(find(displayCustomFlowConfig.value, { id: search.customFlow }), 'nodes'),
    )
    const getMemberShopOrder = async (payload) => {
      const [res, err] = await GetMemberShopOrder(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getMemberShopOrderCount = async (payload) => {
      const [res, err] = await GetMemberShopOrderCount(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        MemberId: get(search, 'member.id') || undefined,
        code: search.code || undefined,
        status: search.status || undefined,
        paymentStatus: search.payment || undefined,
        isPointExchange: true,
        createdAtStart: search.range ? formatDate(search.range[0], 'YYYY-MM-DD HH:mm:ss') : undefined,
        createdAtEnd: search.range ? formatDate(search.range[1], 'YYYY-MM-DD HH:mm:ss') : undefined,
        includeCustomFlowRecord: useCustomFlowFeature.value.record,
        customFlowNodeId: search.customFlowId || undefined,
      }
      await Promise.allSettled([
        getMemberShopOrder(payload),
        getMemberShopOrderCount(payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.value = true
      await Promise.all([
        getTableData(),
        getCustomFlowConfig(),
      ])
      loading.value = false
    }
    const prepareExport = async () => {
      showExportOptions.value = false

      const payload = {
        shopId: shopId.value,
        start: 0,
        // limit: tableOptions.pageLimit,
        MemberId: get(search, 'member.id') || undefined,
        code: search.code || undefined,
        status: search.status || undefined,
        paymentStatus: search.payment || undefined,
        isPointExchange: true,
        createdAtStart: search.range ? formatDate(search.range[0], 'YYYY-MM-DD HH:mm:ss') : undefined,
        createdAtEnd: search.range ? formatDate(search.range[1], 'YYYY-MM-DD HH:mm:ss') : undefined,
        includeCustomFlowRecord: useCustomFlowFeature.value.record,
        customFlowNodeId: search.customFlowId || undefined,
      }
      if (useExportTask.value) {
        const [, err] = await CreateMemberStoreOrderPointExchangeSheetExportTask(payload)
        if (err) {
          window.$message.error(err)
          return
        }
        window.$message(messageOptions.value)
      } else {
        const [res, err] = await GetMemberShopOrderCount(payload)
        if (err) window.$message.error(err)
        exportTotal.value = res.count
        showExportDialog.value = true
        if (!exportTotal.value) {
          window.$message.warning('沒有資料可以匯出')
          return resetExport()
        }
        exportting.value = true
        try {
          const data = await getExportData()
          exportting.value = false
          exportData.value = data
          await formatExportData()
        } catch (error) {
          console.log(error)
          window.$message.error(error)
          exportting.value = false
          exportError.value = true
        }
      }
    }
    const resetExport = () => {
      showExportDialog.value = false
      exportting.value = false
      exportError.value = false
      exportData.value = []
      exportPercentage.value = 0
    }
    const getExportData = async () => {
      const payload = {
        shopId: shopId.value,
        start: 0,
        // limit: exportTotal.value > 50000 ? 50000 : undefined,
        MemberId: get(search, 'member.id') || undefined,
        code: search.code || undefined,
        status: search.status || undefined,
        paymentStatus: search.payment || undefined,
        isPointExchange: true,
        createdAtStart: search.range ? formatDate(search.range[0], 'YYYY-MM-DD HH:mm:ss') : undefined,
        createdAtEnd: search.range ? formatDate(search.range[1], 'YYYY-MM-DD HH:mm:ss') : undefined,
        includeCustomFlowRecord: useCustomFlowFeature.value.record,
      }
      try {
        let response
        await fetchWithAmount(
          GetMemberShopOrder,
          50000,
          exportPercentage,
          exportTotal.value > 50000 ? 50000 : exportTotal.value,
          payload,
          (res) => { response = res })
        if (!response) {
          throw new Error('沒有資料可以匯出')
        }
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    }
    const formatExportData = async () => {
      const data = []
      // let count = 0
      for (const item of exportData.value) {
        const row = await formatRecordData(item)
        data.push(row)
        // count++
        // exportPercentage.value = (count / exportTotal.value) * 100
      }
      const fileName = '點數兌換紀錄'

      ExportExcel(data, fileName, fileName)
    }
    const formatRecordData = async (i) => {
      const orderStatus = get(orderStatusConfig, i.status)
      const paymentStatus = get(orderPaymentStatusConfig, get(i, 'MemberStoreOrderPayment.status'))
      const paymentType = get(orderPaymentTypeConfig, get(i, 'MemberStoreOrderPayment.paymentType'))
      const point = i.pointExchangeAmount
      const price = i.paidAmount
      let displayPrice = '-'
      if (!price) displayPrice = `${point} 點`
      if (price && point) displayPrice = `${point} 點 + $ ${price}`

      // 根據 displayCustomFlowConfig 比對 i.CustomFlowRecords 陣列資料，添加欄位 <customFlow name>:<nodeName>
      const customFlowFields = getExportCustomFlowFieldsData({
        displayCustomFlowConfig: displayCustomFlowConfig.value,
        itemData: i,
      })

      const formatedData = {
        訂單編號: get(i, 'code'),
        訂單日期: get(i, 'createdAt') ? formatDate(get(i, 'createdAt')) : '-',
        姓名: get(i, 'Member.UserInfo.name') || '-',
        會員手機: get(i, 'Member.UserInfo.phone') || '-',
        商品名稱: get(i, 'MemberStoreOrderProducts[0].name') || '-',
        商品數量: get(i, 'MemberStoreOrderProducts[0].quantity') || '-',
        訂單狀態: get(orderStatus, 'label'),
        支付資訊: displayPrice || '-',
        付款方式: get(paymentType, 'label'),
        付款狀態: get(paymentStatus, 'label'),
        ...customFlowFields,
      }

      return formatedData
    }

    onMounted(async () => {
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })

    return {
      tableData,
      tableOptions,
      loading,
      refresh,
      search,
      orderStatusConfig,
      orderPaymentStatusConfig,
      tableDataCount,
      showExportOptions,
      showExportDialog,
      prepareExport,
      exportting,
      exportError,
      exportTotal,
      exportData,
      exportPercentage,
      resetExport,
      customFlowConfig,
      displayCustomFlowConfig,
      useCustomFlowFeature,
      customFlowList,
      showCustomFlow,
      useExportTask,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .test.el-select,
::v-deep .test .el-input {
  max-width: 196px !important;
}
::v-deep .test2.el-select,
::v-deep .test2.el-input,
::v-deep .test2 .el-input,
::v-deep.test2.el-input__inner {
  width: 100% !important;
  max-width: 400px !important;
}
.filters-wrapper {
  @apply flex flex-col gap-[20px] mb-[20px];
}
.filter-row {
  @apply flex items-center gap-[8px];
}

.filter-row .label {
  @apply flex-shrink-0;
}

</style>
./components/MemberShopPointExchangeOrderTable.vue
